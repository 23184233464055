import React, {useCallback, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isDragActive) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;
`;

export default function StyledDropzone({childToParent}){

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };

  
  const [files, setFiles] = useState([]);
  const allFiles = [];

  const onDrop = useCallback(acceptedFiles => {
    console.log('StyledDropzone output');
    console.log(acceptedFiles);

    console.log('all files:');
    console.log(allFiles);

    acceptedFiles.forEach(f => allFiles.push(f));
    console.log('all files:');
    console.log(allFiles);

    childToParent(acceptedFiles);
    setFiles(allFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)})))}, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject} = useDropzone({
    maxFiles: 5,
    accept: 'image/jpeg, image/png',
    onDrop});
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);
  
  return (
    <div className="container">
      <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some images here, or click to select an image</p>
        <p>(Only *.jpeg and *.png images will be accepted)</p>
     
      </Container>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </div>
  );
}