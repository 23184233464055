import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import StyledDropzone from '../../components/StyledDropzone'

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  files = [];

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  };

  fileUpload = async (acceptedFiles) => {
    console.log('Parent:');
    console.log(acceptedFiles);
    acceptedFiles.forEach(f => this.files.push(f));
  };

  handleSubmit = (e) => {
    e.preventDefault()
    console.log('submit files:');
    console.log(this.files);

    if (this.files.length > 0) {
      let encodedFiles = [];
      this.files.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          // Do whatever you want with the file contents
          let base64 = reader.result.substr(reader.result.indexOf(',') + 1);
          encodedFiles.push(base64);
          console.log(reader.result);

          if (encodedFiles.length === this.files.length) {
            this.submitFormAndData(encodedFiles);
          }
        }
      });
    }
    else {
      this.submitFormAndData([]);
    }
  };

  submitFormAndData = (fileData) => {
    let form = document.getElementById('contact');

    console.log('sending attachments:');
    console.log(fileData);

    fetch('/.netlify/functions/send-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: form.querySelector('#name').value,
        message: form.querySelector('#message').value,
        email: form.querySelector('#email').value,
        attachments: fileData,
        _autoresponse: 'Hi ' + form.querySelector('#name').value + ', Thank you for your email. I will get back to you asap.'
      })
    }).then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
 
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Contact</h1>
              <form
                id='contact'
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="_captcha" value="false" />
                <input type="hidden" name="_subject" value="New submission!" />
                <input type="hidden" name="_autoresponse" value="Thanks for your query. I will get back to you asap." />
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    Your name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'message'}>
                    Message
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'message'}
                      onChange={this.handleChange}
                      id={'message'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <StyledDropzone childToParent={this.fileUpload} id={'fileDrop'} name={'fileDrop'}> </StyledDropzone>
                </div>
                <div className="field">
                  <button className="button is-link" type="button" onClick={this.handleSubmit}>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
